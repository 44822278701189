<template>
  <v-app-bar app
             clipped-left
             color="blue"
             dark
             style="z-index: 10">
    <v-app-bar-nav-icon @click="drawer = !drawer" />

    <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.navbar.title') }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               :to="{name:'Profile'}"
               v-bind="attrs"
               v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.navbar.links.profile') }}</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
               v-bind="attrs"
               v-on="on"
               @click="logout()">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      <span>{{ $vuetify.lang.t('$vuetify.navbar.links.logout') }}</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
// Utilities
import { sync } from 'vuex-pathify'

export default {
  name: 'DefaultAppBar',
  computed: { drawer: sync('app/drawer') },
  methods: {
    async logout () {
      try {
        await this.axios.get('/logout', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        }).then(() => {
          this.$session.destroy()
          this.$router.push({
            name: 'Login'
          })
        })
      } catch (e) {
        this.$session.destroy()
        this.$router.push({
          name: 'Login'
        })
      }
    }
  }
}
</script>
